<template>
  <div class="row custom-form-wrapper">
    <div class="col-md-8 col-sm-10 col-md-offset-2 col-sm-offset-1">
      <div class="custom-form app-res">
        <div class="panel-body">
          <div class="panel panel-info app-search-header-panel">
            <div class="row app-search-wrapper">
              <div class="col-md-10 col-md-offset-1">
                <div class="app-search-header">
                  <div class="row app-search-header-row">
                    <div class="col-md-4 header-left app-mt-1">
                      <span>Property Reference Number</span>
                    </div>
                    <div class="col-md-4 header-middle app-mt-1">
                      <input
                        type="text"
                        v-mask="'####-####'"
                        v-model="filterCode"
                        class="form-control"
                      />
                    </div>
                    <div class="col-md-3 app-search-header-btn-search">
                      <button
                        @click="getFormDataBySearchText"
                        type="submit"
                        class="btn btn-success"
                      >
                        Search
                      </button>
                    </div>
                    <div class="col-md-1">
                      <span
                        v-if="aniLabelsLoading"
                        class="fa fa-spinner fa-spin fa-3x fa-fw"
                      ></span>
                    </div>
                  </div>

                  <div class="row app-search-header-row">
                    <div
                      class="col-md-6 col-md-offset-3 app-search-header-btn-add"
                    >
                      <button
                        @click="newFormData"
                        type="submit"
                        class="btn btn-success"
                      >
                        Add new Property
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="panel-body">
          <div class="panel panel-primary">
            <div class="panel-heading">
              <h3 class="panel-title">Property images</h3>
            </div>
            <div class="panel-body">
              <div class="app-image-panel-toolbar">
                <button
                  @click="onClickSelectFiles"
                  type="button"
                  class="btn btn-success"
                >
                  Select images
                </button>
                <button
                  @click="resetFileInput"
                  type="button"
                  class="btn btn-success"
                >
                  Remove all
                </button>
                <span
                  v-if="aniLoadingFilesSelected"
                  class="fa fa-spinner fa-spin fa-2x fa-fw"
                ></span>
                <form ref="frmFileUpload">
                  <input
                    ref="fileUpload"
                    @change="onChangeSelectedFiles"
                    type="file"
                    style="display: none;"
                    accept="image/*"
                    multiple
                  />
                </form>
              </div>
              <div class="row app-grid-column-equal-height">
                <div
                  v-for="(item, index) in propertyImages"
                  :key="index"
                  class="col-md-6 app-overlay-parent"
                >
                  <img
                    :src="item.data"
                    class="img-responsive"
                    alt="Property image"
                  />
                  <div class="app-overlay-item">
                    <button
                      @click="removePropertyImage(index)"
                      type="button"
                      class="btn btn-success"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="panel-body">
          <div class="panel panel-primary">
            <div class="panel-heading">
              <h3 class="panel-title">Property details</h3>
            </div>

            <div class="panel-body">
              <div v-if="fdata.prp_PropertyCode" class="form-group">
                <label for="prp_PropertyCode">Property Reference Number</label>
                <input
                  id="prp_PropertyCode"
                  name="prp_PropertyCode"
                  v-model.trim="fdata.prp_PropertyCode"
                  v-mask="'####-####'"
                  maxlength="8"
                  class="form-control"
                  readonly
                />
              </div>
              <div
                class="form-group"
                v-bind:class="{
                  'has-error': errors.has('prp_ShortDescription')
                }"
              >
                <label for="prp_ShortDescription"
                  >Brief advert (short text)</label
                >
                <textarea
                  id="prp_ShortDescription"
                  name="prp_ShortDescription"
                  v-model.trim="fdata.prp_ShortDescription"
                  v-validate.initial="{ required: true, max: 1000 }"
                  maxlength="1000"
                  rows="4"
                  class="form-control"
                ></textarea>
                <span class="help-block">{{
                  errors.first("prp_ShortDescription")
                }}</span>
              </div>

              <div
                class="form-group"
                v-bind:class="{
                  'has-error': errors.has('prp_LongDescription')
                }"
              >
                <label
                  >Detailed advert (You may copy and paste formatted text from
                  Microsoft Word)
                </label>
                <quill-editor
                  ref="prp_LongDescription"
                  v-model.trim="fdata.prp_LongDescription"
                ></quill-editor>
                <input
                  name="prp_LongDescription"
                  v-model.trim="fdata.prp_LongDescription"
                  v-validate.initial="{ required: true, max: 4000 }"
                  type="hidden"
                />

                <span class="help-block">{{
                  errors.first("prp_LongDescription")
                }}</span>
              </div>

              <div
                class="form-group"
                v-bind:class="{ 'has-error': errors.has('prp_KeyFeatures') }"
              >
                <label for="prp_KeyFeatures"
                  >Key features of the property</label
                >
                <textarea
                  id="prp_KeyFeatures"
                  name="prp_KeyFeatures"
                  v-model.trim="fdata.prp_KeyFeatures"
                  v-validate.initial="{ required: true, max: 1000 }"
                  maxlength="1000"
                  rows="4"
                  class="form-control"
                ></textarea>
                <span class="help-block">{{
                  errors.first("prp_KeyFeatures")
                }}</span>
              </div>

              <div
                class="form-group"
                v-bind:class="{ 'has-error': errors.has('prp_OfferType') }"
              >
                <label for="prp_OfferType">Offer type</label>
                <select
                  id="prp_OfferType"
                  name="prp_OfferType"
                  v-model="fdata.prp_OfferType"
                  v-validate.initial="{ required: true }"
                  class="form-control"
                >
                  <option></option>
                  <option value="1">Rent</option>
                  <option value="2">Sale</option>
                  <option value="3">Lease</option>
                </select>
                <span class="help-block">{{
                  errors.first("prp_OfferType")
                }}</span>
              </div>

              <div
                class="form-group"
                v-bind:class="{ 'has-error': errors.has('prp_PropertyType') }"
              >
                <label for="prp_PropertyType">Property type</label>
                <select
                  id="prp_PropertyType"
                  name="prp_PropertyType"
                  v-model="fdata.prp_PropertyType"
                  v-validate.initial="{ required: true }"
                  class="form-control"
                >
                  <option></option>
                  <option value="1">Detached</option>
                  <option value="2">Semi-detached</option>
                  <option value="3">Terraced</option>
                  <option value="4">Flat</option>
                  <option value="5">Bungalow</option>
                  <option value="6">Land</option>
                  <option value="7">Park Home</option>
                  <option value="8">Student Halls</option>
                </select>
                <span class="help-block">{{
                  errors.first("prp_PropertyType")
                }}</span>
              </div>

              <div
                class="form-group"
                v-bind:class="{ 'has-error': errors.has('prp_Price') }"
              >
                <label for="prp_Price">Price (£)</label>
                <input
                  id="prp_Price"
                  name="prp_Price"
                  v-model.trim="fdata.prp_Price"
                  v-validate.initial="{ required: true, decimal: 2, max: 12 }"
                  maxlength="12"
                  class="form-control"
                />
                <span class="help-block">{{ errors.first("prp_Price") }}</span>
              </div>

              <div
                class="form-group"
                v-bind:class="{ 'has-error': errors.has('prp_Deposit') }"
              >
                <label for="prp_Deposit">Deposit (£)</label>
                <input
                  id="prp_Deposit"
                  name="prp_Deposit"
                  v-model.trim="fdata.prp_Deposit"
                  v-validate.initial="{ decimal: 2, max: 12 }"
                  maxlength="12"
                  class="form-control"
                />
                <span class="help-block">{{
                  errors.first("prp_Deposit")
                }}</span>
              </div>

              <div
                class="form-group"
                v-bind:class="{ 'has-error': errors.has('prp_Bedrooms') }"
              >
                <label for="prp_Bedrooms">Number of bedrooms</label>
                <select
                  id="prp_Bedrooms"
                  name="prp_Bedrooms"
                  v-model="fdata.prp_Bedrooms"
                  v-validate.initial="{ required: true }"
                  class="form-control"
                >
                  <option></option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
                <span class="help-block">{{
                  errors.first("prp_Bedrooms")
                }}</span>
              </div>

              <div
                class="form-group"
                v-bind:class="{ 'has-error': errors.has('prp_Bathrooms') }"
              >
                <label for="prp_Bathrooms">Number of bathrooms</label>
                <select
                  id="prp_Bathrooms"
                  name="prp_Bathrooms"
                  v-model="fdata.prp_Bathrooms"
                  v-validate.initial="{ required: true }"
                  class="form-control"
                >
                  <option></option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
                <span class="help-block">{{
                  errors.first("prp_Bathrooms")
                }}</span>
              </div>

              <div
                class="form-group"
                v-bind:class="{ 'has-error': errors.has('prp_LetType') }"
              >
                <label for="prp_LetType">Let type</label>
                <select
                  id="prp_LetType"
                  name="prp_LetType"
                  v-model="fdata.prp_LetType"
                  v-validate.initial="{ required: true }"
                  class="form-control"
                >
                  <option></option>
                  <option value="1">Long term</option>
                  <option value="2">Short term</option>
                </select>
                <span class="help-block">{{
                  errors.first("prp_LetType")
                }}</span>
              </div>

              <div
                class="form-group"
                v-bind:class="{ 'has-error': errors.has('prp_FurnishType') }"
              >
                <label for="prp_FurnishType">Furnish type</label>
                <select
                  id="prp_FurnishType"
                  name="prp_FurnishType"
                  v-model="fdata.prp_FurnishType"
                  v-validate.initial="{ required: true }"
                  class="form-control"
                >
                  <option></option>
                  <option value="1">Furnished</option>
                  <option value="2">Unfurnished</option>
                </select>
                <span class="help-block">{{
                  errors.first("prp_FurnishType")
                }}</span>
              </div>

              <div
                class="form-group"
                v-bind:class="{ 'has-error': errors.has('prp_ContactName') }"
              >
                <label for="prp_ContactName"
                  >Contact person name (optional)</label
                >
                <input
                  id="prp_ContactName"
                  name="prp_ContactName"
                  v-model.trim="fdata.prp_ContactName"
                  v-validate="{ max: 100, valid_name: { active: true } }"
                  maxlength="100"
                  class="form-control"
                />
                <span class="help-block">{{
                  errors.first("prp_ContactName")
                }}</span>
              </div>

              <div
                class="form-group"
                v-bind:class="{ 'has-error': errors.has('prp_ContactTel') }"
              >
                <label for="prp_ContactTel"
                  >Contact persons mobile (optional)</label
                >
                <input
                  id="prp_ContactTel"
                  name="prp_ContactTel"
                  v-model.trim="fdata.prp_ContactTel"
                  v-validate.initial="{
                    max: 11,
                    regex: '^(07\\d{9})$'
                  }"
                  maxlength="11"
                  class="form-control"
                />
                <span v-if="errors.has('prp_ContactTel')" class="help-block"
                  >Must be valid UK mobile number (eg. 07581234567)</span
                >
              </div>

              <div
                class="form-group"
                v-bind:class="{ 'has-error': errors.has('prp_City') }"
              >
                <label for="prp_City">City/Town</label>
                <select
                  id="prp_City"
                  name="prp_City"
                  v-model="fdata.prp_City"
                  v-validate.initial="{ required: true }"
                  class="form-control"
                  @change="onChangeCity"
                >
                  <option></option>
                  <option
                    v-for="item in cityList"
                    v-bind:value="item.value"
                    :key="item.value"
                    >{{ item.label }}</option
                  >
                </select>
                <span class="help-block">{{ errors.first("prp_City") }}</span>
              </div>

              <div
                class="form-group"
                v-bind:class="{ 'has-error': errors.has('prp_Suburb') }"
              >
                <label for="prp_Suburb">Suburb</label>
                <img
                  v-if="aniLoadingSuburb"
                  src="/assets/img/loading/loading_hr_01.gif"
                  alt="suburb loading"
                />
                <input
                  name="prp_Suburb"
                  v-model="fdata.prp_Suburb"
                  v-validate.initial="{ required: true }"
                  type="hidden"
                />
                <v-select
                  v-model="fdata.prp_Suburb"
                  :options="localityList"
                  :reduce="op => op.value"
                ></v-select>
                <span class="help-block">{{ errors.first("prp_Suburb") }}</span>
              </div>

              <div
                class="form-group"
                v-bind:class="{ 'has-error': errors.has('prp_Address1') }"
              >
                <label for="prp_Address1"
                  >First line of address / House number</label
                >
                <input
                  id="prp_Address1"
                  name="prp_Address1"
                  v-model.trim="fdata.prp_Address1"
                  v-validate.initial="{ max: 50 }"
                  maxlength="50"
                  class="form-control"
                />
                <span class="help-block">{{
                  errors.first("prp_Address1")
                }}</span>
              </div>

              <div
                class="form-group"
                v-bind:class="{ 'has-error': errors.has('prp_Address2') }"
              >
                <label for="prp_Address2">Street / Road</label>
                <input
                  id="prp_Address2"
                  name="prp_Address2"
                  v-model.trim="fdata.prp_Address2"
                  v-validate.initial="{ required: true, max: 50 }"
                  maxlength="50"
                  class="form-control"
                />
                <span class="help-block">{{
                  errors.first("prp_Address2")
                }}</span>
              </div>

              <div
                class="form-group"
                v-bind:class="{ 'has-error': errors.has('prp_Postcode') }"
              >
                <label for="prp_Postcode">Postcode</label>
                <input
                  id="prp_Postcode"
                  name="prp_Postcode"
                  v-model.trim="fdata.prp_Postcode"
                  v-validate.initial="{
                    required: true,
                    max: 8,
                    valid_uk_postcode: { active: true }
                  }"
                  maxlength="8"
                  class="form-control"
                />
                <span v-if="errors.has('prp_Postcode')" class="help-block"
                  >Must be valid UK postcode</span
                >
              </div>

              <div
                class="form-group"
                v-bind:class="{ 'has-error': errors.has('prp_DateAvailable') }"
              >
                <label for="prp_DateAvailable">Available date</label>
                <input
                  id="prp_DateAvailable"
                  name="prp_DateAvailable"
                  v-model.trim="fdata.prp_DateAvailable"
                  v-validate.initial="{
                    required: true,
                    valid_date_format: { active: true }
                  }"
                  placeholder="DD-MMM-YYYY"
                  maxlength="11"
                  class="form-control"
                />
                <span class="help-block">{{
                  errors.first("prp_DateAvailable")
                }}</span>
              </div>

              <div
                class="form-group"
                v-bind:class="{ 'has-error': errors.has('prp_SortValue') }"
              >
                <label for="prp_SortValue">Sort value</label>
                <input
                  id="prp_SortValue"
                  name="prp_SortValue"
                  v-model.trim="fdata.prp_SortValue"
                  v-validate="{ numeric: true }"
                  maxlength="10"
                  class="form-control"
                />
                <span class="help-block">{{
                  errors.first("prp_SortValue")
                }}</span>
              </div>

              <div
                class="form-group"
                v-bind:class="{ 'has-error': errors.has('prp_Status') }"
              >
                <label for="prp_Status">Property status</label>
                <select
                  id="prp_Status"
                  name="prp_Status"
                  v-model="fdata.prp_Status"
                  v-validate.initial="{ required: true }"
                  class="form-control"
                >
                  <option></option>
                  <option value="1">Active</option>
                  <option value="2">Inactive</option>
                  <option value="3">Sold</option>
                  <option value="4">Rented</option>
                  <option value="90">Delete</option>
                </select>
                <span class="help-block">{{ errors.first("prp_Status") }}</span>
              </div>
            </div>

            <div class="btn-submit-wrapper">
              <div v-if="showAnimation" class="app-animation">
                <span class="fa fa-spinner fa-spin fa-3x fa-fw"></span><br />
                <span class="text">Form submission in progress</span>
              </div>
              <button
                v-on:click="saveFormData"
                type="submit"
                class="btn btn-success"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        <div>
          <modal
            name="alert-dialog-property"
            :width="400"
            :height="'auto'"
            :clickToClose="false"
          >
            <div class="panel-primary">
              <div class="panel-heading modal-alert-heading">
                <span class="modal-alert-title">Information</span>
                <button
                  @click="closeAlertDialog"
                  type="button"
                  class="close pull-right"
                >
                  <span
                    class="fa fa-times-circle modal-alert-close-button-icon"
                  ></span>
                </button>
              </div>
              <div class="panel-body modal-alert-body">
                <div class="modal-alert-text-wrapper">
                  <span
                    class="fa fa-exclamation-circle modal-alert-icon"
                  ></span>
                  <span class="modal-alert-text">
                    {{ alertMessage }}
                  </span>
                </div>
                <div class="modal-alert-footer">
                  <button
                    @click="closeAlertDialog"
                    type="button"
                    class="btn btn-default"
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";
/*
    const path = "dist/assets/images/ResourceImages/";
    */
export default {
  props: {
    baseUrl: { type: String }
  },
  data() {
    return {
      fdata: {
        pkey: 0,
        prp_PropertyCode: "",
        prp_ShortDescription: "",
        prp_LongDescription: "",
        prp_KeyFeatures: "",
        prp_OfferType: null,
        prp_PropertyType: null,
        prp_Price: null,
        prp_Deposit: null,
        prp_Bedrooms: null,
        prp_Bathrooms: null,
        prp_LetType: null,
        prp_FurnishType: null,
        prp_ContactName: "",
        prp_ContactTel: "",
        prp_City: null,
        prp_Suburb: null,
        prp_SearchRadius: null,
        prp_Address1: "",
        prp_Address2: "",
        prp_Postcode: "",
        prp_DateAvailable: null,
        prp_ImageListUpdate: [],
        prp_SortValue: null,
        prp_Status: null,
        newFilesSelected: false
      },
      showAnimation: false,
      alertMessage: "",
      PropertyList: [],
      filterCode: "",
      aniLabelsLoading: false,
      aniLoadingFilesSelected: false,
      aniLoadingSuburb: false,
      propertyImages: [],
      cityList: [],
      localityList: []
    };
  },
  methods: {
    ...mapActions(["getToken"]),
    saveFormData: async function() {
      const valStatus = await this.$validator.validateAll();
      if (!valStatus) {
        this.alertMessage = "Form contains validation warnings";
        this.$modal.show("alert-dialog-property");
        return;
      }
      if (this.propertyImages.length == 0) {
        this.alertMessage = "Please upload property images";
        this.$modal.show("alert-dialog-property");
        return;
      }
      this.showAnimation = true;

      let formData = new FormData();
      if (this.fdata.newFilesSelected) {
        this.propertyImages.forEach(item => {
          formData.append("prp_ImageList", item.file);
        });
      } else {
        this.fdata.prp_ImageListUpdate = this.propertyImages.map(p => {
          return { fileName: p.data.substring(1) };
        });
      }

      let fdata = Object.assign({}, this.fdata);
      delete fdata.prp_ImageList;
      formData.append("json", JSON.stringify(fdata));

      const token = await this.getToken({ vm: this });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        }
      };
      let rData = null;
      let actionUrl = `${this.baseUrl}SaveFormData`;
      try {
        ({ data: rData } = await axios.post(actionUrl, formData, config));
      } catch (error) {
        this.showAnimation = false;
        throw error;
      }

      const { status, pid } = rData;
      if (status == 0) {
        this.filterCode = pid;
      } else {
        this.showAnimation = false;
        this.alertMessage = "Form contains validation warnings";
        this.$modal.show("alert-dialog-property");
        return;
      }

      if (!this.fdata.pkey) {
        this.resetFormData();
      }

      this.alertMessage = "Form saved successfully.";
      this.$modal.show("alert-dialog-property");
      this.showAnimation = false;
    },
    getFormDataBySearchText: async function() {
      if (!this.filterCode) {
        return;
      }
      let filterCodeLocal = this.filterCode.replace("-", "");
      this.aniLabelsLoading = true;
      const token = await this.getToken({ vm: this });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: { searchText: filterCodeLocal }
      };
      let rData = null;
      let actionUrl = `${this.baseUrl}GetFormDataBySearchText`;
      try {
        ({ data: rData } = await axios.get(actionUrl, config));
      } catch (error) {
        this.aniLabelsLoading = false;
        throw error;
      }
      if (rData) {
        Object.assign(this.fdata, rData);
        this.propertyImages = Object.assign([], rData.prp_ImageList);
        this.fdata.newFilesSelected = false;
        await this.getLocalityList();
      }
      this.aniLabelsLoading = false;
    },
    getLocalityList: async function() {
      this.aniLoadingSuburb = true;
      const token = await this.getToken({ vm: this });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: { fkey: this.fdata.prp_City }
      };
      let rData = null;
      let actionUrl = `${this.baseUrl}GetLocalityList`;
      try {
        ({ data: rData } = await axios.get(actionUrl, config));
      } catch (error) {
        this.aniLoadingSuburb = false;
        throw error;
      }
      if (rData.length != 0) {
        this.localityList = Object.assign([], rData);
      } else {
        this.fdata.prp_Suburb = null;
        this.localityList = [];
      }
      this.aniLoadingSuburb = false;
    },
    getCityList: async function() {
      this.aniLabelsLoading = true;
      const token = await this.getToken({ vm: this });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      let rData = null;
      let actionUrl = `${this.baseUrl}GetCityList`;
      try {
        ({ data: rData } = await axios.get(actionUrl, config));
      } catch (error) {
        this.aniLabelsLoading = false;
        throw error;
      }
      if (rData) {
        this.cityList = Object.assign([], rData);
      }
      this.aniLabelsLoading = false;
    },
    closeAlertDialog: function() {
      this.$modal.hide("alert-dialog-property");
    },
    newFormData: function() {
      this.resetFormData();
      this.filterCode = "";
    },
    resetFormData: function() {
      Object.assign(this.fdata, {
        pkey: 0,
        prp_PropertyCode: "",
        prp_ShortDescription: "",
        prp_LongDescription: "",
        prp_KeyFeatures: "",
        prp_OfferType: null,
        prp_PropertyType: null,
        prp_Price: null,
        prp_Deposit: null,
        prp_Bedrooms: null,
        prp_Bathrooms: null,
        prp_LetType: null,
        prp_FurnishType: null,
        prp_ContactName: "",
        prp_ContactTel: "",
        prp_City: null,
        prp_Suburb: null,
        prp_SearchRadius: null,
        prp_Address1: "",
        prp_Address2: "",
        prp_Postcode: "",
        prp_DateAvailable: null,
        prp_SortValue: null,
        prp_Status: null
      });
      this.propertyImages = [];
    },
    onChangeCity: async function() {
      this.fdata.prp_Suburb = null;
      this.localityList = [];
      if (this.fdata.prp_City) {
        await this.getLocalityList();
      }
    },
    onClickSelectFiles: function() {
      this.$refs.fileUpload.click();
    },
    onChangeSelectedFiles: function() {
      let files = this.$refs.fileUpload.files;
      if (!(files && files[0])) return;
      let self = this;
      let localImages = [];
      for (let i = 0; i < files.length; i++) {
        this.aniLoadingFilesSelected = true;
        let reader = new FileReader();
        reader.onload = (function(i) {
          return function(e) {
            localImages.push({
              id: i,
              name: files[i].name,
              data: e.target.result,
              file: files[i]
            });
            if (files.length == localImages.length) {
              localImages.sort((a, b) => a.id - b.id);
              self.propertyImages = Object.assign([], localImages);
              self.$refs.frmFileUpload.reset();
              self.aniLoadingFilesSelected = false;
            }
          };
        })(i);
        reader.readAsDataURL(files[i]);
      }
      // console.log(localImages);
      // console.log(this.propertyImages);
      this.fdata.newFilesSelected = true;
    },
    resetFileInput: function() {
      if (this.propertyImages.length > 0) {
        this.$refs.frmFileUpload.reset();
        this.propertyImages = [];
      }
    },
    removePropertyImage: function(index) {
      this.propertyImages.splice(index, 1);
    }
  },
  async mounted() {
    await this.getCityList();
  }
};
</script>
